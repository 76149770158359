import { useLanguage } from "../helpers/dictionary";

export const getPrices = (lang: string) => {
  const prices = __PRICES__;

  const symbol = (() => {
    switch (prices.currency) {
      case "EUR":
        return "€";
      case "GBP":
        return "£";
      case "USD":
        return "$";
      default:
        return "";
    }
  })();

  const formatPrice = (price: number) => {
    const formattedPrice = price.toFixed(2).toString().replace(".", ",");
    switch (lang) {
      case "en":
        return `${symbol}${price}`;
      default:
        return `${formattedPrice} ${symbol}`;
    }
  };

  const localeSubscriptionPrice = formatPrice(prices.subscription_price);
  const localeRebillPrice = formatPrice(prices.rebill_price);

  const interpolate = (text: string) => {
    return text
      .replace(/SUBSCRIPTION_PRICE/g, localeSubscriptionPrice)
      .replace(/REBILL_PRICE/g, localeRebillPrice);
  };

  return {
    ...prices,
    localeSubscriptionPrice,
    localeRebillPrice,
    interpolate,
  };
};

export default function usePrices() {
  const lang = useLanguage();
  return getPrices(lang);
}
