import { GlobalConfigContext } from "@cospex/client/context/GlobalConfigContext";
import { useContext } from "react";

export const useGlobalConfig = () => {
  const context = useContext(GlobalConfigContext);
  if (!context) {
    throw new Error(
      "useGlobalConfig must be used within a CustomizationProvider"
    );
  }
  return context;
};
