import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "page-render-error": "We're experienced an unexpected problem.",
    "page-render-error-description":
      "We're sorry, we are having trouble loading this page. Please reload and try again. If this continues, please try to {{contact}} support, or come back later.",
    contact: "contact",
  },
  fr: {
    "page-render-error": "Nous avons rencontré un problème inattendu.",
    "page-render-error-description":
      "Nous sommes désolés, nous rencontrons des problèmes pour charger cette page. Veuillez recharger et réessayer. Si cela persiste, veuillez essayer de {{contact}} le support, ou revenez plus tard.",
    contact: "contact",
  },
  es: {
    "page-render-error": "Estamos experimentando un problema inesperado.",
    "page-render-error-description":
      "Lo sentimos, estamos teniendo problemas para cargar esta página. Por favor, recarga y vuelve a intentarlo. Si esto continúa, por favor intenta {{contact}} con el soporte, o vuelve más tarde.",
    contact: "contacto",
  },
  de: {
    "page-render-error": "Wir haben ein unerwartetes Problem erlebt.",
    "page-render-error-description":
      "Es tut uns leid, wir haben Probleme beim Laden dieser Seite. Bitte laden Sie die Seite neu und versuchen Sie es erneut. Wenn das Problem weiterhin besteht, wenden Sie sich bitte an den {{contact}} Support oder kommen Sie später wieder.",
    contact: "Kontakt",
  },
  it: {
    "page-render-error": "Stiamo riscontrando un problema inaspettato.",
    "page-render-error-description":
      "Ci dispiace, stiamo avendo problemi a caricare questa pagina. Per favore ricarica e riprova. Se il problema persiste, prova a {{contact}} l'assistenza, o torna più tardi.",
    contact: "contattare",
  },
};

const hook = addDictionary(dictionary, "components/Error");
export default hook;
