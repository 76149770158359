import { Email, Home } from "@mui/icons-material";
import { Alert, Button, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

import useTranslation from "./dictionary";

export default function ErrorComponent() {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100dvh",
        width: "100vw",
        p: 4,
      }}
    >
      <Alert severity="error">{t("page-render-error")}</Alert>
      <Typography
        variant="body1"
        sx={{
          maxWidth: 400,
        }}
      >
        {t("page-render-error-description", { contact: t("contact") })}
      </Typography>
      <Stack direction="row" spacing={2}>
        <Button component="a" variant="outlined" href="/">
          <Home />
        </Button>
        <Button
          variant="contained"
          component="a"
          href="/contact"
          startIcon={<Email />}
        >
          {t("contact")}
        </Button>
      </Stack>
    </Box>
  );
}
