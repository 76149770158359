import { useCustomTranslation } from "@cospex/client/context/CustomTranslationProvider";
import type { SiteLanguages } from "@cospex/client/helpers/dictionary";
import useTranslation from "@cospex/client/hooks/useTranslation";
import { Alert, MenuItem, Select, SxProps, styled } from "@mui/material";

const SelectElement = styled(Select)`
  border-radius: 10px;

  .MuiSelect-select {
    padding: 0.5rem 1rem;
  }

  fieldset {
    border-color: transparent;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.08);
  }
`;

interface ILanguageSelect {
  options: SiteLanguages;
  sx?: SxProps;
}

export default function LanguageSelect({ options, sx }: ILanguageSelect) {
  const { i18n } = useTranslation();
  const { translationMode } = useCustomTranslation();
  if (translationMode) {
    return <Alert severity="warning">Translation mode</Alert>;
  }
  return (
    <SelectElement
      sx={sx}
      value={i18n.language}
      onChange={(e) => {
        localStorage.setItem("lng", e.target.value as string);
        i18n.changeLanguage(e.target.value as string);
      }}
    >
      {options.map(({ value, label }) => (
        <MenuItem key={label + value} value={value}>
          {label}
        </MenuItem>
      ))}
    </SelectElement>
  );
}
