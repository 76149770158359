import { getLanguage } from "@cospex/client/helpers/getLanguage";
import useTranslation from "@cospex/client/hooks/useTranslation";
import * as Sentry from "@sentry/react";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

export type SiteLanguages = {
  value: string;
  label: string;
}[];

export type SupportedLanguage = "en" | "fr" | "it" | "de" | "es";
export type Dictionary = Record<SupportedLanguage, Record<string, string>>;

const dictionaryQueue: {
  dictionary: Dictionary;
  namespace: string;
}[] = [];

const processDictionary = ({
  dictionary,
  namespace,
}: {
  dictionary: Dictionary;
  namespace: string;
}) => {
  Object.entries(dictionary).forEach(([lang, resources]) => {
    i18next.addResourceBundle(lang, namespace, resources);
  });
};

type DictionaryKeys<D extends Dictionary> = {
  [Lang in keyof D]: keyof D[Lang] & string;
}[keyof D];

const addDictionaryBase = <D extends Dictionary>(
  dictionary: D,
  namespace: string
) => {
  // It is possible that the dictionary is added before i18next is initialized
  // In this case, we add it to the queue and process it later
  if (!i18next.isInitialized) {
    dictionaryQueue.push({ dictionary, namespace });
  } else {
    processDictionary({ dictionary, namespace });
  }
};

/**
 * Add a dictionary to the i18n instance. This will return a hook scoped to the passed namespace,
 * which will only have access to the keys of the passed dictionary.
 * @param dictionary - The dictionary to add
 * @param namespace - The namespace to add the dictionary to
 * @returns A hook that can be used to translate strings
 */
export const addDictionary = <D extends Dictionary>(
  dictionary: D,
  namespace: string
) => {
  addDictionaryBase(dictionary, namespace);

  const useTranslationHook = () => {
    const { t, i18n, ...rest } = useTranslation(namespace);

    // typedT that only accepts valid string keys from D
    const typedT = (
      key: DictionaryKeys<D>,
      options?: Record<string, string | null>
    ): string => {
      if (!i18n.getResource(i18n.language, namespace, key)) {
        Sentry.captureException(
          new Error(
            `Translation not found for key ${key} in namespace ${namespace} for language ${i18n.language}`
          ),
          {
            tags: {
              section: "translations",
            },
          }
        );
      }
      return options ? t(key, options) : t(key);
    };

    return {
      t: typedT,
      ...rest,
    };
  };

  return useTranslationHook;
};

/**
 * Add a global dictionary to the i18n instance. This will return a hook scoped to the "global" namespace,
 * which will be used for all translations that are not scoped to a specific namespace. This is useful for handling
 * keys that are not part of the client codebase, such as API errors.
 *
 * Not strictly typed.
 * @param dictionary - The dictionary to add
 * @returns A hook that can be used to translate strings
 */
export const addGlobalDictionary = (dictionary: Dictionary) => {
  addDictionaryBase(dictionary, "global");
  return () => useTranslation("global");
};

/**
 * Initialize the i18n instance. This should be called in the root component of the app.
 * @param languages - The languages to support for this instance
 */
export const initDictionary = (languages: SiteLanguages) => {
  const langs = languages.map((l) => l.value);
  i18next.use(initReactI18next).init({
    resources: {},
    lng: getLanguage(langs),
  });
  dictionaryQueue.forEach(processDictionary);
};

/**
 * Minor convenience hook to get the current language of the i18n instance.
 * @returns The current language
 */
export const useLanguage = () => {
  const { i18n } = useTranslation();
  return i18n.language as SupportedLanguage;
};
