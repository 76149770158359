import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircle } from "@mui/icons-material";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles/createTheme";
import { SxProps } from "@mui/system";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { z } from "zod";

import useTranslation from "./dictionary";

type MarketingEmailProps = {
  sx?: SxProps<Theme>;
};

type MarketingInput = {
  marketingEmail: string;
};
export default function MarketingEmail({ sx }: MarketingEmailProps) {
  const { t } = useTranslation();

  const validationSchema = z.object({
    marketingEmail: z
      .string()
      .min(1, t("required"))
      .email(t("login-email-invalid")),
  });

  const [onValid, setOnValid] = useState(false);
  const { control, handleSubmit } = useForm<MarketingInput>({
    defaultValues: {
      marketingEmail: "",
    },
    resolver: zodResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<MarketingInput> = () => setOnValid(true);

  return (
    <Stack
      sx={{
        ...sx,
      }}
    >
      {onValid ? (
        <Stack sx={{ flexDirection: "row" }}>
          <Typography>
            <CheckCircle
              sx={{
                color: "success.main",
                verticalAlign: "bottom",
                fontSize: "1.4rem",
                mr: "5px",
              }}
            />

            {t("marketing-email-confirm-valid")}
          </Typography>
        </Stack>
      ) : (
        <Stack
          sx={{
            width: "100%",
            maxWidth: "315px",
            gap: "10px",
          }}
        >
          <Typography variant="h6">{t("marketing-email-receive")}</Typography>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Stack sx={{ flexDirection: "row", gap: "10px" }}>
              <Controller
                name="marketingEmail"
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    label={t("marketing-email-enter")}
                    variant="filled"
                    size="small"
                  />
                )}
              />

              <Stack sx={{ alignItems: "center" }}>
                <Button
                  variant="contained"
                  sx={{ bgcolor: "primary.main", color: "white", mt: "10px" }}
                  type="submit"
                >
                  {t("marketing-email-confirm")}
                </Button>
              </Stack>
            </Stack>
          </Box>
          <Typography variant="body2">
            {t("marketing-email-confirm-text")}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
}
