import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "header-contact": "Contact",
    "header-login": "Login",
    "header-account": "Account",
    "header-logout": "Logout",
  },
  fr: {
    "header-contact": "Contact",
    "header-login": "Se connecter",
    "header-account": "Compte",
    "header-logout": "Déconnexion",
  },
  it: {
    "header-contact": "Contattaci",
    "header-login": "Accesso",
    "header-account": "Profilo",
    "header-logout": "Disconnetti",
  },
  es: {
    "header-contact": "Contacto",
    "header-login": "Acceso",
    "header-account": "Cuenta",
    "header-logout": "Cerrar sesión",
  },
  de: {
    "header-contact": "Kontakt",
    "header-login": "Anmeldung",
    "header-account": "Konto",
    "header-logout": "Abmelden",
  },
};
const hook = addDictionary(dictionary, "components/Menu");
export default hook;
