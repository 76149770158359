export const cardShadow = "0px 7px 18px rgba(0, 0, 0, 0.04)";
export const header = {
  height: 40,
  verticalPadding: {
    xs: 2,
    md: 4,
  },
};

export const transitionFunction = "cubic-bezier(0.16, 1, 0.3, 1)";
