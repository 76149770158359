const urlParams = new URLSearchParams(window.location.search);
const urlAbtestVersion = urlParams.get("abtest_version");

const abtestVersion =
  urlAbtestVersion || localStorage.getItem("abtest_version");

if (urlAbtestVersion) {
  localStorage.setItem("abtest_version", urlAbtestVersion);
}

export const ABTEST_VERSION = abtestVersion;
