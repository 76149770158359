import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "footer-description":
      "We are a team of amazing software engineers, creating awesome web and mobile apps.",
    "footer-link-cookies": "Cookie policy",
    "footer-link-privacy": "Privacy policy",
    "footer-link-terms": "Terms and conditions",
    "footer-link-contact": "Contact us",
    "footer-link-pricing": "Pricing",
    "footer-link-refunds": "Refunds",
    "footer-link-login": "Login",
    "footer-link-unsubscribe": "Unsubscribe",
  },
  fr: {
    "footer-description":
      "Nous sommes une équipe composée de formidables concepteurs de logiciels, qui créent des applications web et mobiles géniales.",
    "footer-link-cookies": "Politique des cookies",
    "footer-link-privacy": "Politique de confidentialité",
    "footer-link-terms": "Conditions d'utilisation",
    "footer-link-contact": "Contactez nous",
    "footer-link-pricing": "Prix",
    "footer-link-refunds": "Remboursements",
    "footer-link-login": "Se connecter",
    "footer-link-unsubscribe": "Se désabonner",
  },
  it: {
    "footer-description":
      "Siamo un team di ingegneri software straordinari che creano fantastiche app web e mobili.",
    "footer-link-cookies": "Polizza cookie",
    "footer-link-privacy": "Polizza privacy",
    "footer-link-terms": "Termini e condizioni",
    "footer-link-contact": "Contattaci",
    "footer-link-pricing": "Tariffe",
    "footer-link-refunds": "Rimborsi",
    "footer-link-login": "Login",
    "footer-link-unsubscribe": "Annulla",
  },
  es: {
    "footer-description":
      "Somos un equipo de destacados ingenieros de software que diseñamos excepcionales aplicaciones web y móviles.",
    "footer-link-cookies": "Política de cookies",
    "footer-link-privacy": "Política de privacidad",
    "footer-link-terms": "Términos y condiciones",
    "footer-link-contact": "Contacto",
    "footer-link-pricing": "Precios",
    "footer-link-refunds": "Reembolsos",
    "footer-link-login": "Acceso",
    "footer-link-unsubscribe": "Cancelar suscripción",
  },
  de: {
    "footer-description":
      "Wir sind ein Team großartiger Software-Ingenieure, die großartige Web- und mobile Apps erstellen.",
    "footer-link-cookies": "Cookie-Richtlinie",
    "footer-link-privacy": "Datenschutzrichtlinie",
    "footer-link-terms": "Geschäftsbedingungen",
    "footer-link-contact": "Kontaktieren Sie uns",
    "footer-link-pricing": "Preisgestaltung",
    "footer-link-refunds": "Rückerstattungen",
    "footer-link-login": "Anmeldung",
    "footer-link-unsubscribe": "Abbestellen",
  },
};
const hook = addDictionary(dictionary, "tracker/components/Footer");
export default hook;
