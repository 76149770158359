import ErrorComponent from "@cospex/client/components/Error";
import FrontAppChat from "@cospex/client/components/FrontAppChat";
import Header from "@cospex/client/components/Header";
import Footer from "@cospex/client/tracker/components/Footer";
import { SxProps } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Outlet, useLocation } from "react-router-dom";

interface AppProps {
  homeRoutes?: string[];
  headerSx?: (isHomePage: boolean) => SxProps | undefined;
}

/**
 * Shared App component that supports customizations needed across different tracker websites.
 *
 * Customization options:
 *
 * 1. homeRoutes - Specifies which routes should be treated as "home" pages, which may need special styling.
 *    - locatefast: Uses ["/", "/localiza"] for both homepage and localization pages
 *    - mobilocate: Uses ["/", "/lokalisieren"] for German localization
 *    - phonemobi: Uses only ["/"] for homepage
 *    - phoneway: Uses ["/", "/lokalisieren"] for German localization
 *    - supergeo: Uses default ["/"]
 *
 * 2. headerSx - Provides custom styling for the header component based on current route
 *    - locatefast: Sets `mb: -13` on home/localiza pages to adjust spacing
 *    - mobilocate: Uses absolute positioning with z-index on home/lokalisieren pages
 *    - phonemobi: Sets `mb: -13` on homepage similar to locatefast
 *    - phoneway: Uses absolute positioning with z-index on home/lokalisieren pages
 *
 * The ChatId is standardized across all sites to maintain consistency in customer support.
 */

export default function App({ homeRoutes = ["/"], headerSx }: AppProps = {}) {
  const location = useLocation();
  const isHomePage = homeRoutes.some(
    (route) =>
      location.pathname === route || location.pathname.startsWith(route)
  );

  return (
    <Sentry.ErrorBoundary fallback={<ErrorComponent />}>
      <FrontAppChat chatId="671597a2060872f63b291ab93ae9d08a" />
      <Header
        menu={{
          dashboardHref: "/dashboard/sms",
        }}
        sx={headerSx ? headerSx(isHomePage) : undefined}
      />
      <Outlet />
      <Footer />
    </Sentry.ErrorBoundary>
  );
}
