import { addDictionary } from "@cospex/client/helpers/dictionary";

const dictionary = {
  en: {
    "marketing-email-receive": "Receive our marketing emails",
    "marketing-email-enter": "Enter your email here",
    "marketing-email-confirm": "Confirm",
    "marketing-email-confirm-text":
      'By clicking on "Confirm" after entering your email address, you agree to receive our emails for marketing purposes.',
    "marketing-email-confirm-valid":
      "Consent to receive marketing emails has been taken into account.",
    "login-email-invalid": "Email is invalid",
    required: "Required",
  },
  fr: {
    "marketing-email-receive": "Recevoir nos emails marketing",
    "marketing-email-enter": "Renseignez ici votre email...",
    "marketing-email-confirm": "Confirmer",
    "marketing-email-confirm-text":
      'En cliquant sur "Confirmer" après avoir indiqué votre adresse email, vous certifiez accepter de recevoir nos mails à des fins marketing.',
    "marketing-email-confirm-valid":
      "Le consentement pour la réception de courriels marketing a été pris en compte.",
    "login-email-invalid": "L'email est invalide",
    required: "Requis",
  },
  it: {
    "marketing-email-receive": "Ricevi le nostre mail di marketing",
    "marketing-email-enter": "Inserisci qui la tua email",
    "marketing-email-confirm": "Conferma",
    "marketing-email-confirm-text":
      'Cliccando su "Conferma" dopo aver inserito il tuo indirizzo email accetti di ricevere le nostre mail a scopo marketing.',
    "marketing-email-confirm-valid":
      "È stato preso in considerazione il consenso a ricevere e-mail di marketing.",
    "login-email-invalid": "L'email non è valida",
    required: "Necessario",
  },
  es: {
    "marketing-email-receive":
      "Recibe nuestros correos electrónicos de marketing",
    "marketing-email-enter": "Introduce tu correo electrónico aquí",
    "marketing-email-confirm": "Confirmar",
    "marketing-email-confirm-text":
      'Al hacer clic en "Confirmar" después de ingresar tu dirección de correo electrónico, aceptas recibir nuestros correos electrónicos con fines de marketing.',
    "marketing-email-confirm-valid":
      "Se ha tenido en cuenta el consentimiento para recibir correos electrónicos de marketing.",
    "login-email-invalid": "El correo electrónico no es válido",
    required: "Obligatorio",
  },
  de: {
    "marketing-email-receive": "Erhalten Sie unsere Marketing-E-Mails",
    "marketing-email-enter": "Geben Sie hier Ihre E-Mail-Adresse ein",
    "marketing-email-confirm": "Bestätigen",
    "marketing-email-confirm-text":
      'Indem Sie nach Angabe Ihrer E-Mail-Adresse auf "Bestätigen" klicken, bestätigen Sie, dass Sie damit einverstanden sind, unsere E-Mails zu Marketingzwecken zu erhalten.',
    "marketing-email-confirm-valid":
      "Die Zustimmung zum Erhalt von Marketing-E-Mails wurde berücksichtigt.",
    "login-email-invalid": "E-Mail ist ungültig",
    required: "Erforderlich",
  },
};
const hook = addDictionary(dictionary, "components/MarketingEmail");
export default hook;
