import "@cospex/client/abtest";
// import "@cospex/client/cacheManagement";
import { AuthProvider } from "@cospex/client/context/AuthProvider";
import CustomTranslationProvider from "@cospex/client/context/CustomTranslationProvider";
import GlobalConfigProvider, {
  IGlobalConfig,
} from "@cospex/client/context/GlobalConfigContext";
import { CssBaseline, Theme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      cacheTime: 0,
    },
  },
});

if (import.meta.env.PROD) {
  let dsn = "";
  if (import.meta.env.VITE_SENTRY_WEB_DSN !== "") {
    dsn = import.meta.env.VITE_SENTRY_WEB_DSN as string;
  }

  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
      }),
      Sentry.thirdPartyErrorFilterIntegration({
        // Specify the application keys that you specified in the Sentry bundler plugin
        filterKeys: ["client"],

        // Defines how to handle errors that contain third party stack frames.
        // Possible values are:
        // - 'drop-error-if-contains-third-party-frames'
        // - 'drop-error-if-exclusively-contains-third-party-frames'
        // - 'apply-tag-if-contains-third-party-frames'
        // - 'apply-tag-if-exclusively-contains-third-party-frames'
        behaviour: "drop-error-if-exclusively-contains-third-party-frames",
      }),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    ignoreErrors: [
      //e.g. https://neteck.sentry.io/share/issue/9133b0cf676f4b94a526f3372c295243/
      // Seems to be 3rd party translation error
      /^jb$/,
    ],
  });
}

interface IGlobalProvider {
  theme: Theme;
  children: React.ReactNode;
  config: IGlobalConfig;
}

export default function GlobalProvider({
  theme,
  children,
  config,
}: IGlobalProvider) {
  return (
    <React.StrictMode>
      <GlobalConfigProvider value={config}>
        <CustomTranslationProvider>
          <QueryClientProvider client={queryClient}>
            <AuthProvider>
              <ThemeProvider theme={theme}>
                <ParallaxProvider>
                  <CssBaseline />
                  {children}
                </ParallaxProvider>
              </ThemeProvider>
            </AuthProvider>
          </QueryClientProvider>
        </CustomTranslationProvider>
      </GlobalConfigProvider>
    </React.StrictMode>
  );
}
