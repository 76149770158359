import Logo from "@cospex/client/components/Logo";
import Menu from "@cospex/client/components/Menu";
import { useGlobalConfig } from "@cospex/client/hooks/useGlobalConfig";
import { header } from "@cospex/client/styles";
import {
  Box,
  Container,
  Stack,
  SxProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useLocation } from "react-router-dom";

interface IMenuOptions {
  dashboardHref: string;
  iconColor?: string;
}

interface IHeader {
  menu?: IMenuOptions;
  sx?: SxProps;
}

const Header = ({ menu, sx = {} }: IHeader) => {
  const location = useLocation();
  const { logo } = useGlobalConfig();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isPaymentPage = location.pathname.includes("payment");

  if (isPaymentPage && isMobile) {
    return null;
  }

  return (
    <Box
      sx={{
        position: "relative",
        zIndex: 10,
        py: header.verticalPadding,
        overflow: "hidden",
        ...sx,
      }}
    >
      <Container sx={{ height: header.height }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <Logo logoPath={logo.src} sx={logo.sx} textOnly={logo.textOnly} />
          {!isPaymentPage && menu && (
            <Menu
              dashboardHref={menu.dashboardHref}
              iconColor={menu.iconColor}
            />
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Header;
